<template>
  <div>
    <van-nav-bar
      :title="title"
      :left-text="leftText"
      :right-text="rightText"
      :left-arrow="leftArrow"
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <template #right>
        <slot name="right"></slot>
      </template>
      <template #left>
        <slot name="left"></slot>
      </template>
    </van-nav-bar>
    <div class="main_contain">
      <slot></slot>

    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "直播CRM",
    },
    leftText: {
      type: String,
      default: "返回",
    },
    rightText: {
      type: String,
      default: "",
    },
    leftArrow: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$emit('click-left')
    },
    onClickRight() {
      this.$emit('click-right')
    },
  },
};
</script>

<style lang="less" scoped>
// .main_contain {
//   height: calc(100vh - 46px);
//   overflow-y: scroll;
// }
</style>